import { useEffect, FC, useState } from 'react'
import { RoutesWrapper } from 'router'
import { Footer, Header, MobileNavBar } from 'components'
import styles from './PageLayout.module.scss'
import { useResponsive } from 'hooks'
import { useAppDispatch, useAppSelector } from 'hooks/useTypedSelector'
import { fetchSettingData } from 'store/features/Setting/settingSlice'
import { fetchBasketList } from 'store/features/Basket/basketSlice'
import { fetchWatchList } from 'store/features/Watchlist/watchListSlice'
import MetaPixel from '../MetaPixel'


interface PageLayoutProps {
  idx: number;
}

const PageLayout: FC = () => {
  const { isTablet } = useResponsive()
  const settingSlice = useAppSelector(state => state?.settingSlice)
  const  [id,setId] = useState(null)
  const [scriptAdded, setScriptAdded] = useState(false)

  const head: any = settingSlice?.data?.data
  const storeData: any = settingSlice?.data?.data

  const dispatch = useAppDispatch()


  useEffect(()=>{
    setId(head?.fb_pixel_id)
  },[head])

  useEffect(() => {
    dispatch(fetchSettingData())

    dispatch(fetchBasketList())
    dispatch(fetchWatchList())
  }, [dispatch])



  useEffect(() => {
    const checkFaviconExists = async (url: string) => {
      try {
        const response = await fetch(url, { method: 'HEAD' });
        
return response.ok;
      } catch (error) {
        console.error('Error checking favicon:', error);
        
return false;
      }
    };

    const setFavicon = async () => {
      const faviconUrl = '/favicon.ico';
      const faviconExists = await checkFaviconExists(faviconUrl);

      if (faviconExists) {
        const faviconLink = document.createElement('link');
        faviconLink.rel = 'icon';
        faviconLink.type = 'image/x-icon';
        faviconLink.href = faviconUrl;

        document.head.appendChild(faviconLink);
      } else {
        console.log('Favicon file not found.');
      }
    };

    setFavicon();
  }, []);


  useEffect(() => {
    const descriptionMetaTag = document.querySelector('meta[name="description"]')
    if (descriptionMetaTag) {
      descriptionMetaTag.setAttribute('content', `Интернет магазин ${head?.name} `)
    }


    if (storeData?.ga_tracking_id) {
      const gmScript = document.createElement('script')

      gmScript.src = `https://www.googletagmanager.com/gtag/js?id=${storeData?.ga_tracking_id}`

      gmScript.async = true

      document.head.appendChild(gmScript)

      const gaScript = document.createElement('script')

      gaScript.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag() {window.dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${storeData.ga_tracking_id}');
`

      document.head.appendChild(gaScript)
    }

    if (!scriptAdded) {
      if (head !== null) {
        document.title = head?.name

        head?.forEach((item: any) => {
          const script = document.createElement('script')
          const PropsItem = item?.props?.[0]

          for (const key in PropsItem) {
            if (PropsItem.hasOwnProperty(key)) {
              const value = PropsItem[key]
              script.setAttribute(key, value)
            }
          }

          script.setAttribute('data-live-chat-id', '64f44c52d10a09501205142a')
          script.async = item.async

          script.src = item.url

          script.onerror = function (event) {
            console.error('Error loading script:', event)
          }

          document.head.prepend(script)
        })

        setScriptAdded(true)
      }
    }
  }, [head , scriptAdded, storeData])



  return (
    <main className={styles.wrapper}>

      <MetaPixel id={head?.fb_pixel_id} />
      <Header />

      <div className={styles.wrapper__container}>
        <RoutesWrapper />
      </div>

      <Footer />

      {isTablet && <MobileNavBar />}
    </main>
  )
}

export default PageLayout
