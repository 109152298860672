import React, { useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom';
import { PageLayout } from 'components';
import './styles/index.global.scss';
import { Provider } from 'react-redux';
import store from 'store/store';

const App: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);


  useEffect(() => {
    setTimeout(()=>{
      setLoading(false)
    },100)
  }, [])

  if (loading) {
    return <div className="flex justify-center items-center h-screen">
      <div className="rounded-full h-20 w-20 bg-yellow-400 animate-ping"></div>
    </div>
  }

  return (
    <Provider store={store}>
      <BrowserRouter>
        <PageLayout  />
      </BrowserRouter>
    </Provider>
  );
};

export default App;
